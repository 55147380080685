import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/naming-convention */
import { Avatar, Div, FormItem, Input, NativeSelect, Panel, PanelHeaderBack, PanelHeaderSubmit, SimpleCell, Spinner } from '@vkontakte/vkui';
import { ethers } from 'ethers';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icon28ChevronDownOutline } from '@vkontakte/icons';
import { VldBuilder, vlds } from 'validatorus-react';
import { RPCprovider } from '../../logic/wallet';
import { listNetwork, listNetworkLogo } from '../../logic/list';
import { packTokens, FullBl, NtoStr, getParameterByName, weiToEth } from '../../logic/utils';
export const Send = (props) => {
    const [firstRender, setFirstRender] = React.useState(false);
    // const [ userTokens, setUserTokens ] = React.useState<CustomToken[]>(listTokens)
    const [networkUser, setNetworkUser] = React.useState(props.wallet?.network ?? RPCprovider[0]);
    const [idTokens, setIdTokens] = React.useState('100');
    const [position, setPosition] = React.useState(0);
    const [currentToken, setCurrentToken] = React.useState(undefined);
    const [fee, setFee] = React.useState('0');
    const location = useLocation();
    const history = useNavigate();
    const AddressSend = new VldBuilder()
        .with(vlds.VHex)
        .with(vlds.VLen, 42, 42)
        .withFname('Address');
    const AmountSend = new VldBuilder()
        .with(vlds.VNumber, 0, 100000000)
        .with(vlds.VLen, 1, 100)
        .withFname('Amount');
    const TokenName = new VldBuilder()
        .with(vlds.VLen, 1, 30)
        .withFname('Token');
    async function sendToken() {
        props.openPop();
        if (AddressSend.value === '' || AmountSend.value === '') {
            props.closePop(false);
            props.consoleLog('AddressSend or AmountSend', false);
            return;
        }
        if (!props.wallet || !props.walletBsc || !props.walletPolygon) {
            props.consoleLog('wallet not found', false);
            props.closePop(false);
            return;
        }
        if (!TokenName.value) {
            props.consoleLog('token not found', false);
            props.closePop(false);
            return;
        }
        if (!currentToken) {
            props.consoleLog('currentToken not found', false);
            props.closePop(false);
            return;
        }
        const nanoAmount = ethers.utils.parseUnits(AmountSend.value, currentToken[Number(TokenName.value)].decimals);
        const { network } = currentToken[Number(TokenName.value)];
        const isNativeToken = currentToken[Number(TokenName.value)].address === 'native';
        const walletNetwork = network === 0 ? props.wallet : network === 1 ? props.walletBsc : props.walletPolygon;
        if (!walletNetwork) {
            props.consoleLog('walletNetwork not found', false);
            props.closePop(false);
            return;
        }
        const tim = setTimeout(() => {
            props.closePop(false);
            props.consoleLog('Time out', false);
        }, 15000);
        console.log(currentToken[Number(TokenName.value)].address);
        console.log(AddressSend.value);
        console.log(nanoAmount.toString());
        console.log('network', network);
        let res;
        if (isNativeToken) {
            res = await walletNetwork.sendNativToken(AddressSend.value, nanoAmount.toString());
        }
        else {
            res = await walletNetwork.sendToken(currentToken[Number(TokenName.value)].address, AddressSend.value, nanoAmount.toString());
        }
        if (!res) {
            props.consoleLog('Error', false);
            props.closePop(false);
            return;
        }
        clearTimeout(tim);
        props.closePop(true);
        props.consoleLog('Success', true);
        props.setActiveModal(null);
        history('/');
    }
    async function getComm() {
        if (AddressSend.value === '' || AmountSend.value === '') {
            // props.closePop(false)
            // props.consoleLog('AddressSend or AmountSend', false)
            return;
        }
        if (!props.wallet || !props.walletBsc || !props.walletPolygon) {
            // props.consoleLog('wallet not found', false)
            // props.closePop(false)
            return;
        }
        if (!TokenName.value) {
            // props.consoleLog('token not found', false)
            // props.closePop(false)
            return;
        }
        if (!currentToken) {
            // props.consoleLog('currentToken not found', false)
            // props.closePop(false)
            return;
        }
        const nanoAmount = ethers.utils.parseUnits(AmountSend.value, currentToken[Number(TokenName.value)].decimals);
        const { network } = currentToken[Number(TokenName.value)];
        const isNativeToken = currentToken[Number(TokenName.value)].address === 'native';
        const walletNetwork = network === 0 ? props.wallet : network === 1 ? props.walletBsc : props.walletPolygon;
        let fee1 = false;
        if (isNativeToken) {
            fee1 = await walletNetwork.getFeeTr(AddressSend.value, nanoAmount.toString());
        }
        else {
            fee1 = await walletNetwork.getFeeTrToken(currentToken[Number(TokenName.value)].address, AddressSend.value, nanoAmount.toString());
        }
        if (!fee1) {
            props.consoleLog('Error', false);
            props.closePop(false);
            return;
        }
        const dec = weiToEth(fee1, props.userTokens[currentToken[Number(TokenName.value)].network].decimals);
        console.log('dec', dec);
        setFee(dec);
    }
    function reRender() {
        setIdTokens((Number(idTokens) + 1).toString());
    }
    useEffect(() => {
        if (!firstRender) {
            setFirstRender(true);
            // getBalance()
            const search = getParameterByName('t');
            if (search) {
                const curr = packTokens(props.userTokens.filter(t => t.symbol.toLowerCase() === search));
                if (curr.length > 0) {
                    setCurrentToken(curr[0]);
                    setPosition(1);
                }
                else {
                    props.consoleLog('Token not found', false);
                    history('/');
                }
            }
            else {
                // props.consoleLog('Error load token', false)
                // history('/')
            }
        }
    }, []);
    useEffect(() => {
        if (currentToken) {
            console.log('change currentToken');
            const ind = currentToken.findIndex(t => BigInt(t.balance) > 0n);
            TokenName.change(ind !== -1 ? ind.toString() : '0');
        }
    }, [currentToken]);
    useEffect(() => {
        getComm();
    }, [AmountSend.value, AddressSend.value]);
    return (_jsxs(Panel, { id: props.id, children: [position === 0
                ? _jsxs(Div, { className: "block_wallet", children: [_jsx("div", { className: "block-balance", children: _jsxs("div", { className: 'block_menu', children: [_jsx(PanelHeaderBack, { onClick: () => history('/'), label: 'Back' }), _jsx("div", { style: { margin: '0 8px 0 0', fontSize: '16px' }, children: "Send" }), _jsx(PanelHeaderSubmit, { className: "right_btn", disabled: packTokens(props.userTokens).filter(t => Number(FullBl(t)) > 0).length === 0, onClick: () => {
                                            setPosition(1);
                                            setCurrentToken(packTokens(props.userTokens).filter(t => Number(FullBl(t)) > 0)[0]);
                                        }, children: "Done" })] }) }), props.wallet
                            ? _jsx("div", { style: { marginTop: '16px' }, id: idTokens, children: _jsx("div", { className: "list_tokens", children: packTokens(props.userTokens).map((token, key) => (_jsx(SimpleCell, { disabled: Number(FullBl(token)) === 0, badgeAfterTitle: token.map((t, k) => (_jsx("img", { src: listNetworkLogo[t.network] }, k))), onClick: () => {
                                            setCurrentToken(token);
                                            setPosition(1);
                                        }, before: _jsx("img", { src: token[0].icon, className: 'imgToken' }), after: _jsxs("div", { children: [_jsx("div", { children: FullBl(token) }), Number(FullBl(token)) !== 0
                                                    ? _jsxs("div", { style: { opacity: '0.6', fontSize: '12px' }, children: ["$", NtoStr(Number(FullBl(token)) * Number(token[0].price))] }) : null] }), subtitle: `$${NtoStr(token[0].price)}`, children: token[0].symbol }, key))) }) })
                            : null, _jsx("br", {})] }) : null, position === 1 && currentToken
                ? _jsxs(Div, { className: "block_wallet", children: [_jsx(FormItem, { children: _jsxs("div", { style: { display: 'flex', alignItems: 'center', justifyContent: 'space-between' }, children: [_jsx("b", { children: "Send" }), _jsx(SimpleCell, { onClick: () => setPosition(0), before: _jsx(Avatar, { src: currentToken[0].icon, size: 28 }), after: _jsx(Icon28ChevronDownOutline, {}), children: currentToken[0].symbol })] }) }), _jsx(FormItem, { bottom: TokenName.error, children: _jsx(NativeSelect, { onChange: e => TokenName.change(e.target.value), value: TokenName.value, children: currentToken.map((token, key) => (_jsx("option", { value: key, disabled: BigInt(token.balance) === 0n, children: `${listNetwork[token.network]}` }, key))) }) }), _jsx(FormItem, { bottom: AddressSend.error, children: _jsx(Input, { value: AddressSend.value, onChange: e => AddressSend.change(e.target.value), placeholder: 'Address', status: AddressSend.iserr }) }), _jsx(FormItem, { top: AmountSend.error, bottom: _jsxs("div", { style: { display: 'flex', alignItems: 'center', justifyContent: 'space-between' }, children: [_jsx("span", { children: "Balance" }), _jsx("span", { children: currentToken[Number(TokenName.value)]
                                            ? FullBl([currentToken[Number(TokenName.value)]]) : '0' })] }), children: _jsx(Input, { value: AmountSend.value, placeholder: '0', type: "number", onChange: e => AmountSend.change(e.target.value), status: AmountSend.iserr }) }), _jsx(FormItem, { children: _jsx(SimpleCell, { disabled: true, after: fee !== '0'
                                    ? `${fee} ${props.userTokens[currentToken[Number(TokenName.value)]?.network ?? 0].symbol}`
                                    : _jsx(Spinner, { size: "small", style: { margin: '20px 0' } }), children: "Fee" }) }), _jsx("br", {}), _jsx("br", {}), _jsx(Div, { className: "block-balance block-bottom-fixed", children: _jsxs("div", { className: 'block_menu', children: [_jsx(PanelHeaderBack, { onClick: () => setPosition(0), label: 'Back' }), _jsx("div", { style: { margin: '0 8px 0 0', fontSize: '16px' } }), _jsx(PanelHeaderSubmit, { className: "right_btn", disabled: !(AddressSend.iserr === 'valid'
                                            && AmountSend.iserr === 'valid'
                                            && (currentToken[Number(TokenName.value)]
                                                ? FullBl([currentToken[Number(TokenName.value)]]) : '0') >= AmountSend.value), onClick: () => sendToken(), children: "Send" })] }) })] }) : null] }));
};
