import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/naming-convention */
import { CellButton, Div, Panel, PanelHeaderBack, PanelHeaderSubmit } from '@vkontakte/vkui';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
export const Settings = (props) => {
    const [firstRender, setFirstRender] = React.useState(false);
    const location = useLocation();
    const history = useNavigate();
    useEffect(() => {
        if (!firstRender) {
            setFirstRender(true);
            // getBalance()
        }
    }, []);
    return (_jsx(Panel, { id: props.id, children: _jsxs(Div, { className: "block_wallet", children: [_jsx("div", { className: "block-balance", children: _jsxs("div", { className: 'block_menu', children: [_jsx(PanelHeaderBack, { onClick: () => history('/'), label: 'Back' }), _jsx("div", { style: { margin: '0 8px 0 0', fontSize: '16px' }, children: "Settings" }), _jsx(PanelHeaderSubmit, { className: "right_btn", onClick: () => history('/'), children: "Done" })] }) }), _jsxs("div", { children: [_jsx("br", {}), _jsx(CellButton, { centered: true, onClick: () => props.setActiveModal('view_key'), children: "Save private key" }), _jsx(CellButton, { mode: "danger", centered: true, onClick: () => props.setActiveModal('del_wallet'), children: "Delete my account" })] }), _jsx("br", {})] }) }));
};
