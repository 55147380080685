import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/naming-convention */
import { Avatar, Div, Panel, SimpleCell } from '@vkontakte/vkui';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icon28ArrowDownToSquareOutline, Icon28SettingsOutline, Icon28ShareExternal } from '@vkontakte/icons';
import { listNetworkLogo } from '../../logic/list';
import { NtoStr, packTokens, FullBl } from '../../logic/utils';
export const Main = (props) => {
    const [firstRender, setFirstRender] = React.useState(false);
    const [idTokens, setIdTokens] = React.useState('100');
    const location = useLocation();
    const history = useNavigate();
    function reRender() {
        setIdTokens((Number(idTokens) + 1).toString());
    }
    useEffect(() => {
        if (!firstRender) {
            setFirstRender(true);
            // getBalance()
        }
    }, []);
    return (_jsx(Panel, { id: props.id, children: _jsxs(Div, { className: "block_wallet", children: [_jsxs("div", { className: "block-balance", children: [props.wallet
                            ? _jsx(Div, { children: _jsx("div", { className: "new_block_balance", children: _jsxs("h2", { className: "balance-string", style: { marginTop: 0 }, children: ["$", NtoStr(props.balanceUser)] }) }) })
                            : null, _jsxs("div", { style: { display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }, children: [_jsx(SimpleCell, { onClick: () => props.setActiveModal('recive'), children: _jsxs("div", { style: {
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            flexDirection: 'column'
                                        }, children: [_jsx(Avatar, { children: _jsx(Icon28ArrowDownToSquareOutline, {}) }), _jsx("span", { style: { marginTop: '8px' }, children: "Receive" })] }) }), _jsx(SimpleCell, { onClick: () => history('/send'), children: _jsxs("div", { style: {
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            flexDirection: 'column'
                                        }, children: [_jsx(Avatar, { children: _jsx(Icon28ShareExternal, {}) }), _jsx("span", { style: { marginTop: '8px' }, children: "Send" })] }) }), _jsx(SimpleCell, { onClick: () => history('/settings'), children: _jsxs("div", { style: {
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            flexDirection: 'column'
                                        }, children: [_jsx(Avatar, { children: _jsx(Icon28SettingsOutline, {}) }), _jsx("span", { style: { marginTop: '8px' }, children: "Settings" })] }) })] })] }), props.wallet
                    ? _jsx("div", { style: { marginTop: '16px' }, id: idTokens, children: _jsx("div", { className: "list_tokens", children: packTokens(props.userTokens).map((token, key) => (_jsx(SimpleCell, { badgeAfterTitle: token.map((t, k) => (_jsx("img", { src: listNetworkLogo[t.network] }, k))), onClick: () => {
                                    props.openCurrentToken(token);
                                }, before: _jsx("img", { src: token[0].icon, className: 'imgToken' }), after: _jsxs("div", { children: [_jsx("div", { children: FullBl(token) }), Number(FullBl(token)) !== 0
                                            ? _jsxs("div", { style: { opacity: '0.6', fontSize: '12px' }, children: ["$", NtoStr(Number(FullBl(token)) * Number(token[0].price))] }) : null] }), subtitle: `$${NtoStr(token[0].price)}`, children: token[0].symbol }, key))) }) })
                    : null, _jsx("br", {})] }) }));
};
