import * as Bip39 from 'bip39';
import { BigNumber, ethers } from 'ethers';
// import crypto from 'crypto'
import * as crypto from 'crypto-js';
import { randomBytes } from 'ethers/lib/utils';
import { StorageWallet } from './storage';
import tokenAbi from '../token.json';
const globalSecret = '7275737369616e207761727368697020676f206675636b20796f757273656c66';
export const RPCprovider = [
    {
        name: 'mainnet',
        url: 'https://rpc.ankr.com/eth',
        id: 1,
        coin: 'ETH',
        nano: 8
    },
    {
        name: 'bsc',
        url: 'https://rpc.ankr.com/bsc',
        id: 56,
        coin: 'BNB',
        nano: 8
    },
    {
        name: 'polygon',
        url: 'https://side-dawn-sea.matic.quiknode.pro/ce9f1f0946472d034b646717ed6b29a175b85dba/',
        id: 137,
        coin: 'MATIC',
        nano: 18
    }
];
export class WalletETH {
    constructor(network = 0) {
        this._storage = new StorageWallet();
        this._network = RPCprovider[network];
        this._provider = new ethers.providers.JsonRpcProvider(this._network.url);
    }
    async syncWallet(param) {
        const loadData = this.loadHashSaveData();
        if (this.isSaveWallet()) {
            const privateKey = WalletETH.HashToKey(param.psw, loadData.iv, loadData.hash);
            if (!privateKey) {
                this._error = { code: 11, text: 'Invalid psw' };
                return this;
            }
            this._wallet = WalletETH.getWalletFromKey(privateKey);
            if (!this._wallet) {
                this._error = { code: 12, text: 'wallet dont save' };
            }
        }
        else {
            this._wallet = WalletETH.genNewWallet();
            this._hash = await WalletETH.KeyToHash(this._wallet.privateKey, param.psw, this._wallet.address);
            if (!this._hash) {
                this._error = { code: 10, text: 'Invalid hash' };
                return this;
            }
            this.saveWallet(this._hash);
        }
        return this;
    }
    loadWalletFromMnemonic(mnemonic) {
        // if (this.isSaveWallet()) {
        this._wallet = ethers.Wallet.fromMnemonic(mnemonic);
        if (!this._wallet) {
            this._error = { code: 12, text: 'wallet dont save' };
        }
        return this;
        // }
        // this._error = { code: 11, text: 'wallet don`t save' }
        // return undefined
    }
    async changeProvider(network) {
        if (!this._wallet) {
            return false;
        }
        this._network = RPCprovider.find(x => x.name === network) ?? RPCprovider[0];
        this._provider = new ethers.providers.JsonRpcProvider(this._network.url);
        const balance = await this._provider.getBalance(this._wallet?.address);
        console.log(balance);
        return true;
    }
    static async KeyToHash(privateKey, psw, address) {
        console.log('Start encrypt');
        const iv = Buffer.from(randomBytes(16)).toString('hex');
        console.log('psw', psw);
        const secret = Buffer.from(psw, 'utf8').toString('hex');
        const key = iv + secret + globalSecret;
        console.log('key', key);
        console.log('privateKey', privateKey);
        try {
            const hashFromKey = crypto.AES.encrypt(privateKey, key).toString();
            // const hashFromKey = crypto.createCipheriv('aes-256-cbc', key, iv)
            //     .update(privateKey, 'hex', 'hex')
            //     .toString()
            console.log('hashFromKey', hashFromKey);
            return {
                iv: iv.toString(),
                hashFromKey,
                address
            };
        }
        catch (error) {
            console.error(error);
            return undefined;
        }
    }
    static HashToKey(psw, iv, hash) {
        console.log('Start decrypt');
        const secret = Buffer.from(psw, 'utf8').toString('hex');
        const key = iv + secret + globalSecret;
        // const key = crypto.scryptSync(secret, iv, 32) // salt
        console.log('secret', secret);
        console.log('key', key);
        try {
            const decyptedData = crypto.AES.decrypt(hash, key).toString(crypto.enc.Utf8);
            // const decyptedData = crypto
            //     .createDecipheriv('aes-256-cbc', key, iv)
            //     .update(hash, 'hex', 'hex')
            //     .toString()
            console.log('decyptedData', decyptedData);
            return decyptedData;
        }
        catch (error) {
            console.error(error);
            return undefined;
        }
    }
    static genNewWallet() {
        const mnemonic = Bip39.generateMnemonic();
        console.log(mnemonic);
        // const privateKey = `0x${Bip39.mnemonicToSeedSync(mnemonic).toString('hex')}`
        // console.log(privateKey)
        const wallet = ethers.Wallet.fromMnemonic(mnemonic);
        // const wallet = new ethers.Wallet(privateKey)
        return wallet;
    }
    static getWalletFromKey(privateKey) {
        const wallet = ethers.Wallet.fromMnemonic(privateKey);
        return wallet;
    }
    async getBalance() {
        if (!this._wallet) {
            console.error('wallet undefind');
            return false;
        }
        const balance = await this._provider.getBalance(this._wallet.address);
        return balance;
    }
    async getBalanceToken(address) {
        if (!this._wallet) {
            console.error('wallet undefind');
            return false;
        }
        const contract = new ethers.Contract(address, tokenAbi, this._provider);
        const balance = await contract.balanceOf(this._wallet.address);
        return balance;
    }
    async getFeeTr(address, amount) {
        if (!this._wallet) {
            console.error('wallet undefind');
            return false;
        }
        // const contract = new ethers.Contract(addressToken, tokenAbi, this._provider)
        // const data = contract.interface.encodeFunctionData('transfer', [ address, amount ])
        const gasPrice = await ethers.getDefaultProvider().getGasPrice();
        const tr = {
            to: address,
            value: BigNumber.from(amount),
            chainId: this._network.id
        };
        const limit = await this._provider.estimateGas({
            from: this._wallet.address,
            to: tr.to,
            value: BigNumber.from(amount),
            data: tr.data
        });
        const transactionFee = gasPrice.mul(limit);
        console.log(transactionFee);
        return transactionFee;
    }
    async getFeeTrToken(addressToken, address, amount) {
        if (!this._wallet) {
            console.error('wallet undefind');
            return false;
        }
        const contract = new ethers.Contract(addressToken, tokenAbi, this._provider);
        const data = contract.interface.encodeFunctionData('transfer', [address, amount]);
        const gasPrice = await ethers.getDefaultProvider().getGasPrice();
        const tr = {
            to: addressToken,
            value: ethers.utils.parseEther('0'),
            chainId: this._network.id,
            data
        };
        const limit = await this._provider.estimateGas({
            from: this._wallet.address,
            to: tr.to,
            value: ethers.utils.parseUnits('0.000', 'ether'),
            data: tr.data
        });
        const transactionFee = gasPrice.mul(limit);
        console.log(transactionFee);
        return transactionFee;
    }
    async sendNativToken(address, amount) {
        if (!this._wallet) {
            console.error('wallet undefind');
            return false;
        }
        const nonce = await this._provider.getTransactionCount(this._wallet.address);
        const tr = {
            to: address,
            value: BigNumber.from(amount),
            chainId: this._network.id,
            nonce
        };
        const estimatedGas = await this._provider.estimateGas(tr);
        const gasPrice = await this._provider.getGasPrice();
        tr.gasPrice = gasPrice;
        tr.gasLimit = estimatedGas;
        try {
            const signTr = await this._wallet.signTransaction(tr);
            const sendTr = await this._provider.sendTransaction(signTr);
            console.log(sendTr);
            return sendTr;
        }
        catch (err) {
            console.error(err);
            return false;
        }
    }
    async sendToken(addressToken, address, amount) {
        if (!this._wallet) {
            console.error('wallet undefind');
            return false;
        }
        const nonce = await this._provider.getTransactionCount(this._wallet.address);
        const contract = new ethers.Contract(addressToken, tokenAbi, this._provider);
        const data = contract.interface.encodeFunctionData('transfer', [address, amount]);
        // contract.connect(this._wallet)
        // const res = await contract.connect(this._provider).transfer(address, amount)
        // console.log('sending')
        // return res
        // const sendTr2 = await contract.callStatic.transfer(address, amount)
        // return sendTr2
        const tr = {
            to: addressToken,
            value: ethers.utils.parseEther('0'),
            chainId: this._network.id,
            nonce,
            data
        };
        const limit = await this._provider.estimateGas({
            from: this._wallet.address,
            to: addressToken,
            value: ethers.utils.parseUnits('0.000', 'ether'),
            data
        });
        // console.log('address', address)
        // console.log('addressToken', addressToken)
        // console.log('tr', tr)
        // const estimatedGas = await this._provider.estimateGas(tr)
        const gasPrice = await this._provider.getGasPrice();
        // this._provider.
        tr.gasPrice = gasPrice;
        tr.gasLimit = BigNumber.from((Number(limit) * 1.2).toFixed(0));
        // tr.maxPriorityFeePerGas = ethers.utils.parseUnits('3', 'wei')
        // console.log('gasLimit', estimatedGas)
        try {
            const signTr = await this._wallet.signTransaction(tr);
            const sendTr = await this._provider.sendTransaction(signTr);
            console.log(sendTr);
            return sendTr;
        }
        catch (err) {
            console.error(err);
            return false;
        }
    }
    isSaveWallet() {
        return this._storage.get('iv') && this._storage.get('hash') && this._storage.get('address');
    }
    loadHashSaveData() {
        return { iv: this._storage.get('iv'), hash: this._storage.get('hash'), address: this._storage.get('address') };
    }
    clearAllData() {
        return this._storage.del('iv')
            && this._storage.del('hash')
            && this._storage.del('address');
    }
    saveWallet(param) {
        return this._storage.save('iv', param.iv)
            && this._storage.save('hash', param.hashFromKey)
            && this._storage.save('address', param.address);
    }
    async delWallet(psw) {
        const loadData = this.loadHashSaveData();
        const privateKey = await WalletETH.HashToKey(psw, loadData.iv, loadData.hash);
        if (!privateKey) {
            return false;
        }
        try {
            const wall = await WalletETH.getWalletFromKey(privateKey);
            if (wall.address) {
                this._storage.del('iv');
                this._storage.del('hash');
                this._storage.del('public');
                this._storage.del('address');
                return true;
            }
            return false;
        }
        catch (error) {
            console.error(error);
            return false;
        }
    }
    get wallet() {
        return this._wallet;
    }
    get error() {
        return this._error;
    }
    get network() {
        return this._network;
    }
}
