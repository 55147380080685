import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/naming-convention */
import { Avatar, Div, Panel, PanelHeaderBack, PanelHeaderSubmit, SimpleCell } from '@vkontakte/vkui';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icon28ArrowDownToSquareOutline, Icon28ShareExternal } from '@vkontakte/icons';
import { listNetwork } from '../../logic/list';
import { packTokens, FullBl, NtoStr } from '../../logic/utils';
export const Token = (props) => {
    const [firstRender, setFirstRender] = React.useState(false);
    const [idTokens, setIdTokens] = React.useState('100');
    const [position, setPosition] = React.useState(0);
    const location = useLocation();
    const history = useNavigate();
    function reRender() {
        setIdTokens((Number(idTokens) + 1).toString());
    }
    useEffect(() => {
        if (!firstRender) {
            setFirstRender(true);
            // getBalance()
        }
    }, []);
    return (_jsx(Panel, { id: props.id, children: position === 0
            ? _jsxs(Div, { className: "block_wallet", children: [_jsx("div", { className: "block-balance", children: _jsxs("div", { className: 'block_menu', children: [_jsx(PanelHeaderBack, { onClick: () => history('/'), label: 'Back' }), _jsx("div", { style: { margin: '0 8px 0 0', fontSize: '16px' }, children: props.currentToken ? props.currentToken[0].symbol : 'Token' }), _jsx(PanelHeaderSubmit, { className: "right_btn", onClick: () => history('/'), children: "Done" })] }) }), props.currentToken
                        ? _jsx(Div, { children: _jsx("div", { className: "new_block_balance", children: _jsxs("h2", { className: "balance-string", children: ["$", FullBl(props.currentToken)] }) }) }) : null, props.currentToken !== undefined
                        ? _jsxs("div", { style: { display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }, children: [_jsx(SimpleCell, { onClick: () => props.setActiveModal('recive'), children: _jsxs("div", { style: {
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            flexDirection: 'column'
                                        }, children: [_jsx(Avatar, { children: _jsx(Icon28ArrowDownToSquareOutline, {}) }), _jsx("span", { style: { marginTop: '8px' }, children: "Receive" })] }) }), _jsx(SimpleCell, { disabled: packTokens(props.currentToken).filter(t => Number(FullBl(t)) > 0).length === 0, onClick: () => {
                                        history(`/send?t=${props.currentToken
                                            ? props.currentToken[0].symbol.toLowerCase() : ''}`);
                                    }, children: _jsxs("div", { style: {
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            flexDirection: 'column'
                                        }, children: [_jsx(Avatar, { children: _jsx(Icon28ShareExternal, {}) }), _jsx("span", { style: { marginTop: '8px' }, children: "Send" })] }) })] }) : null, props.currentToken
                        ? _jsx("div", { style: { marginTop: '16px' }, id: idTokens, children: _jsx("div", { className: "list_tokens", children: props.currentToken.map((token, key) => (_jsx(SimpleCell, { badgeAfterTitle: _jsx("div", { className: "bage_block", children: listNetwork[token.network] }), before: _jsx("img", { src: token.icon, className: 'imgToken' }), after: _jsxs("div", { children: [_jsx("div", { children: FullBl([token]) }), Number(FullBl([token])) !== 0
                                                ? _jsxs("div", { style: { opacity: '0.6', fontSize: '12px' }, children: ["$", NtoStr(Number(FullBl([token])) * Number(token.price))] }) : null] }), subtitle: `$${NtoStr(token.price)}`, children: token.symbol }, key))) }) })
                        : null, _jsx("br", {})] }) : null }));
};
