import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Div, FormItem, Input, Panel } from '@vkontakte/vkui';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { VldBuilder, vlds } from 'validatorus-react';
export const Register = (props) => {
    const [firstRender, setFirstRender] = React.useState(false);
    const pswBuilder = new VldBuilder()
        .with(vlds.VLen, 8, 128)
        .withFname('Password');
    const location = useLocation();
    const history = useNavigate();
    useEffect(() => {
        if (!firstRender) {
            setFirstRender(true);
        }
    }, []);
    return (_jsx(Panel, { id: props.id, children: _jsx(Div, { className: "block_wallet", children: _jsxs("div", { className: "block-balance", children: [_jsxs(FormItem, { children: [" ", _jsx("h2", { style: { fontSize: '24px' }, children: "Login Polus Wallet" })] }), _jsx(FormItem, { top: "Password", bottom: pswBuilder.error, children: _jsx(Input, { value: pswBuilder.value, onChange: e => pswBuilder.change(e.target.value), status: pswBuilder.iserr, type: "password" }) }), _jsx(FormItem, { children: _jsx(Button, { disabled: pswBuilder.iserr !== 'valid', onClick: () => props.loadWallet(pswBuilder.value), size: 'l', stretched: true, children: "Unlock" }) })] }) }) }));
};
