import { BigNumber, ethers } from 'ethers';
import axios from 'axios';
export function weiToEth(nanoAmount, decimals) {
    // console.log(nanoAmount)
    const amount = ethers.utils.formatUnits(BigNumber.from(nanoAmount), decimals);
    let stringAmount = Number(amount).toPrecision(2);
    if (Number(stringAmount) === 0) {
        stringAmount = Number(amount).toPrecision(3);
    }
    if (Number(stringAmount) === 0) {
        stringAmount = Number(amount).toPrecision(4);
    }
    if (Number(stringAmount) === 0) {
        stringAmount = Number(amount).toPrecision(5);
    }
    if (Number(stringAmount) === 0) {
        stringAmount = Number(amount).toFixed(1);
    }
    return stringAmount;
}
export function addStr(addr) {
    if (!addr || addr.length < 32)
        return '';
    return addr.replace(addr.slice(5, 38), '...');
}
export function packTokens(userTokens) {
    const resUserTokens = [[]];
    for (let i = 0; i < userTokens.length; i++) {
        if (i === 0) {
            resUserTokens[0][0] = userTokens[i];
        }
        else if (resUserTokens[resUserTokens.length - 1][0].symbol === userTokens[i].symbol) {
            resUserTokens[resUserTokens.length - 1].push(userTokens[i]);
        }
        else {
            resUserTokens.push([userTokens[i]]);
        }
    }
    return resUserTokens;
}
export async function getPriceToken(tokensList) {
    const _listTokens = tokensList;
    const listNamed = [];
    for (let i = 0; i < tokensList.length; i++) {
        listNamed.push(tokensList[i][0].namePrice);
    }
    const data = await axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=${listNamed.join(',')}&vs_currencies=usd`);
    if (!data)
        return _listTokens;
    if (!data.data)
        return _listTokens;
    for (let i = 0; i < _listTokens.length; i++) {
        for (let i2 = 0; i2 < _listTokens[i].length; i2++) {
            // console.log(data.data[_listTokens[i][i2].namePrice])
            if (data.data[_listTokens[i][i2].namePrice]) {
                _listTokens[i][i2].price = data.data[_listTokens[i][i2].namePrice].usd;
            }
            else {
                console.error('not found coin ', _listTokens[i][i2].namePrice);
            }
        }
    }
    return _listTokens;
}
export function NtoStr(n1) {
    const n2 = Number(Number(n1.toString()).toFixed(2));
    n2.toLocaleString('en', {
        currency: 'usd',
        maximumFractionDigits: 2,
        maximumSignificantDigits: 2
    });
    return n2;
}
export function FullBl(t1) {
    let full = 0n;
    for (let i = 0; i < t1.length; i++) {
        full += BigInt((t1[i].balance));
    }
    const fullStr = weiToEth(full.toString(), t1[0].decimals);
    if (Number(fullStr) === 0)
        return '0';
    return fullStr;
}
export const sleep = (ms) => new Promise(r => setTimeout(r, ms));
export function getParameterByName(name, url = window.location.href) {
    const name1 = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${name1}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results)
        return null;
    if (!results[2])
        return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
