import eth from '../img/weth.svg';
import matic from '../img/matic.svg';
import bnb from '../img/bnb.svg';
import usdt from '../img/usdt.svg';
import dai from '../img/dai.svg';
import usdc from '../img/usdc.svg';
const listNetwork = [
    'ERC-20',
    'BEP-20',
    'POLYGON'
];
const listNetworkLogo = [
    eth, bnb, matic
];
const listTokens = [
    {
        name: 'Ethereum',
        symbol: 'ETH',
        address: 'native',
        decimals: 8,
        network: 0,
        icon: eth,
        balance: '0',
        price: '0',
        namePrice: 'ethereum'
    },
    {
        name: 'BNB',
        symbol: 'BNB',
        address: 'native',
        decimals: 18,
        network: 1,
        icon: bnb,
        balance: '0',
        price: '0',
        namePrice: 'binancecoin'
    },
    {
        name: 'Matic',
        symbol: 'MATIC',
        address: 'native',
        decimals: 18,
        network: 2,
        icon: matic,
        balance: '0',
        price: '0',
        namePrice: 'matic-network'
    },
    {
        name: 'Tether USD',
        symbol: 'USDT',
        address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
        decimals: 6,
        network: 0,
        icon: usdt,
        balance: '0',
        price: '1',
        namePrice: 'tether'
    },
    {
        name: 'Tether USD',
        symbol: 'USDT',
        address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
        decimals: 6,
        network: 2,
        icon: usdt,
        balance: '0',
        price: '1',
        namePrice: 'tether'
    },
    {
        name: 'Dai Stablecoin',
        symbol: 'DAI',
        address: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
        decimals: 18,
        network: 0,
        icon: dai,
        balance: '0',
        price: '1',
        namePrice: 'dai'
    },
    {
        name: 'Dai Stablecoin',
        symbol: 'DAI',
        address: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
        decimals: 18,
        network: 1,
        icon: dai,
        balance: '0',
        price: '1',
        namePrice: 'dai'
    },
    {
        name: 'Dai Stablecoin',
        symbol: 'DAI',
        address: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
        decimals: 18,
        network: 2,
        icon: dai,
        balance: '0',
        price: '1',
        namePrice: 'dai'
    },
    {
        name: 'USD Coin',
        symbol: 'USDC',
        address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
        decimals: 6,
        network: 0,
        icon: usdc,
        balance: '0',
        price: '1',
        namePrice: 'usd-coin'
    },
    {
        name: 'USD Coin',
        symbol: 'USDC',
        address: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
        decimals: 6,
        network: 1,
        icon: usdc,
        balance: '0',
        price: '1',
        namePrice: 'usd-coin'
    },
    {
        name: 'USD Coin',
        symbol: 'USDC',
        address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
        decimals: 6,
        network: 2,
        icon: usdc,
        balance: '0',
        price: '1',
        namePrice: 'usd-coin'
    }
];
export { listTokens, listNetwork, listNetworkLogo };
